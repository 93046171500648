const Layout = ({ title, img, text, isLeft, id }) => {
	const divClass = isLeft
		? 'container d-flex flex-row pb-5 justify-content-between p-0'
		: 'container d-flex flex-row-reverse pb-5 justify-content-between p-0';
	return (
		<>
			<h2 className="masajes-title text-center pb-5 pt-5">{title}</h2>
			<div className={divClass}>
				<div className="msj-img">
					<img loading="lazy" src={img} alt={img} width="400px" />
				</div>
				<div className="msj-text text">
					{text}
					<span id={id}></span>
				</div>
			</div>
		</>
	);
};

export default Layout;
