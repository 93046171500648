import { Navbar } from '.';

const ViewsHeader = ({ bg, routeDesc, title }) => {
	return (
		<div>
			<div>
				<section
					className="parallax img-p-1"
					style={{ backgroundImage: `url(${bg})` }}
				>
					<Navbar />
					<div className="caption-left">
						<ul>
							<li>
								<a href="/">INICIO /</a>
							</li>
							<li>{routeDesc}</li>
						</ul>
						<h1>{title}</h1>
					</div>
				</section>
			</div>
		</div>
	);
};

export default ViewsHeader;
