import {
	Conoceme,
	MasajesComponent,
	Terapias,
	Testimonios,
	Header,
} from './MainPage';

export const Home = () => {
	return (
		<div>
			<Header />
			<Conoceme />
			<MasajesComponent />
			<Terapias />
			<Testimonios />
		</div>
	);
};
