import { Spinner } from 'react-bootstrap';
import React, { Suspense } from 'react';

const LazyLoad = ({ children }) => {
	return (
		<Suspense
			fallback={
				<div className="spinner-container">
					<Spinner animation="border" role="status" variant="primary">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</div>
			}
		>
			{children}
		</Suspense>
	);
};

export default LazyLoad;
